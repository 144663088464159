import React from 'react'
import { Link } from 'react-router-dom'

class Dashboard extends React.Component {
  renderButton = item => {
    return (
      <Link as={item.as} to={item.pathname}>
        <div className={`btn btn-primary d-flex flex-column home_button`}>
          <div>
            <i className={`fas ${item.icon}`}></i>
          </div>
          <div className="flex-fill d-flex justify-content-center align-items-center">{item.name}</div>
        </div>
      </Link>
    )
  }
  render() {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
        <div className="mt-5">
          <h3>Pannello di gestione</h3>
        </div>
        <div className="home_buttons_div">
          {this.props.pages.filter(p => p.in_home === true).map(p => this.renderButton(p))}
        </div>
      </div>
    )
  }
}

export default Dashboard
