import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../axios'
import config from '../config'

export const tasksLabel = [
  { id: 'aggiunte_enologiche', name: 'Aggiunte enologiche' },
  { id: 'analisi', name: 'Analisi' },
  { id: 'avvio_fermentazione', name: 'Avvio Fermentazione' },
  { id: 'chiarifica', name: 'Chiarifica' },
  { id: 'controllo', name: 'Controllo' },
  { id: 'destinazione', name: 'Destinazione' },
  { id: 'filtrazione', name: 'Filtrazione' },
  { id: 'lavaggio', name: 'Lavaggio' },
  { id: 'massa', name: 'Massa' },
  { id: 'operazioni_speciali', name: 'Operazioni Speciali' },
  { id: 'ossigenazione', name: 'Ossigenazione' },
  { id: 'pressatura', name: 'Pressatura' },
  { id: 'rimontaggio', name: 'Rimontaggio' },
  { id: 'salasso', name: 'Salasso' },
  { id: 'scarico_uva', name: 'Scarico Uva' },
  { id: 'scarico_vino', name: 'Scarico Vino' },
  { id: 'sfecciatura', name: 'Sfecciatura' },
  { id: 'travaso', name: 'Travaso' },
]

const initialState = {
  histories: {},
  total: 0,
  loading: false,
  show_form: false,
  current_op: {},
  errors: {},
  current_page: 1,
  filtered_containers: [],
  filtered_task: [],
  operators: [],
}

export const getOperators = createAsyncThunk('histories/getOperators', async (_, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/users?roles=Operatore`,
      method: 'GET',
    })
    let operators = response.data.map(o => ({
      id: o.id,
      value: o.first_name + ' ' + o.last_name,
      fullname: o.first_name + ' ' + o.last_name,
      username: o.username,
      icon: o.icon,
      iconLibrary: o.icon_library,
    }))
    return operators
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})

export const getHistories = createAsyncThunk('histories/get_histories', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let current_page = parseInt(offset + 1 / limit) + 1 || 1
    let state = thunkApi.getState().histories
    let url = `${config.SERVER_API_URL}/histories?`
    if (filter) url = url + `&q=${encodeURIComponent(filter)}`
    if (state.show_disabled) url = url + `&disabled=true`
    if (limit) url = url + `&_end=${encodeURIComponent(limit)}`
    if (offset) url = url + `&_start=${encodeURIComponent(offset)}`
    if (sort_direction) url = url + `&_order=${encodeURIComponent(sort_direction)}`
    if (sort_column) url = url + `&_sort=${encodeURIComponent(sort_column)}`
    if (state.filtered_containers.length > 0)
      url = url + `&container=${encodeURIComponent(state.filtered_containers.join(','))}`
    if (state.filtered_task.length > 0) url = url + `&task=${encodeURIComponent(state.filtered_task.join(','))}`

    let response = await axios({
      url,
      method: 'GET',
    })
    let total = parseInt(response.headers['x-total-count'])
    return { histories: response.data, total, current_page }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})

export const historiesSlice = createSlice({
  name: 'histories',
  initialState,

  reducers: {
    setLoading: state => {
      state.loading = true
    },
    filterContainers: (state, action) => {
      state.filtered_containers = action.payload
      state.histories = []
    },
    filterTask: (state, action) => {
      state.filtered_task = action.payload
      state.histories = []
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentOp: state => {
      state.current_op = {}
    },
    setCurrentOp: (state, action) => {
      state.current_op = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getHistories.fulfilled, (state, action) => {
        let { histories, total, current_page } = action.payload
        state.histories = current_page === 1 ? histories : [...state.histories, ...histories]
        state.total = total
        state.current_page = current_page
        state.loading = false
      })
      .addCase(getHistories.rejected, state => {
        state.histories = []
        state.total = 0
        state.current_page = 1
        state.loading = false
      })
      .addCase(getOperators.fulfilled, (state, action) => {
        state.operators = action.payload
      })
      .addCase(getOperators.rejected, state => {
        state.operators = []
      })
  },
})

export const { setLoading, resetCurrentOp, toggleShowForm, setCurrentOp, filterContainers, filterTask } =
  historiesSlice.actions
export default historiesSlice.reducer
