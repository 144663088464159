import React from 'react'

class MassaSummary extends React.Component {
  total = (this.props.containers ?? []).reduce((acc, v) => {
    return (acc = acc + parseFloat(v.selQty ?? 0))
  }, 0)

  renderPercentage = value => {
    let val = isNaN(value) ? 0 : value
    let percentage = ((parseFloat(val) * 100) / this.total).toFixed(2)
    return isNaN(percentage) ? 0 : percentage
  }

  render() {
    let { containers, borderColor = '#156e92' } = this.props
    if (!containers) return null
    return (
      <div className="row pt-3 pb-2">
        <div className="ps-0 pb-2">Riepilogo massa:</div>
        {containers.map((c, idx) => (
          <div
            key={c.id}
            className="border p-1"
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}>
            <div
              style={{
                width: '10%',
                color: '#156e93',
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
              {c.name}
            </div>
            <div style={{ width: '51%', textAlign: 'left' }}>{c.content_name}</div>
            <div style={{ width: '20%' }}>{c.selQty ?? 0} hl</div>
            <div
              style={{
                width: '19%',
                textAlign: 'right',
                fontWeight: 'bold',
              }}>
              {this.renderPercentage(c.selQty)}%{' '}
            </div>
          </div>
        ))}
        {containers.length > 0 && (
          <div
            key="totale"
            className="border p-1"
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              borderTopWidth: 1,
              borderColor: 'lightgrey',
            }}>
            <div className="ps-3" style={{ width: '61%', fontWeight: 'bold' }}>
              Totale:
            </div>
            <div className="fw-bold" style={{ width: '20%' }}>
              {this.total} hl
            </div>
            <div
              style={{
                width: '19%',
                textAlign: 'right',
                fontWeight: 'bold',
              }}>
              100.00%
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default MassaSummary
