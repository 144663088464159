import React from 'react'
import { Button, Form, Alert } from 'react-bootstrap'
import i18next from 'i18next'
import hash from 'object-hash'
import { Trans } from 'react-i18next'

import validateFields from 'lib/validators/user'
import { updateErrors } from 'store/users'

const { t } = i18next

const initialState = {
  id: null,
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  role: '',
  password: '',
  confirm_password: '',
  show_password: true,
  enabled: true,
}

class UserForm extends React.Component {
  static defaultProps = {
    closeForm: () => {},
    onSave: () => {},
    errors: {},
    user: initialState,
  }
  state = {
    user: initialState,
    user_hash: '',
  }

  setUser = () => {
    let user = {
      ...this.state.user,
      ...this.props.user,
    }
    let show_password = user.id ? false : true
    let user_hash = hash(user)
    this.setState({ user, user_hash, show_password })
    this.initialHash = hash(user)
  }
  componentDidMount() {
    this.setUser()
  }

  componentDidUpdate = prevProps => {
    if (prevProps.user.id !== this.props.user.id) {
      this.setUser()
    }
  }

  updateHash = () => {
    let { user } = this.state
    this.setState({ user_hash: hash(user) })
  }

  handleChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }
    delete errors[name]
    if (name === 'password') delete errors['confirm_password']
    if (name === 'confirm_password') delete errors['password']
    this.props.dispatch(updateErrors({ errors }))
    user[name] = value
    this.setState({ user }, () => this.updateHash())
  }
  handleSelectChange = e => {
    let errors = { ...this.props.errors }
    delete errors['role']
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ user: { ...this.state.user, role: e.target.value } }, () => this.updateHash())
  }
  handleIsEnabled = e => {
    let user = { ...this.state.user }
    user['enabled'] = e.target.checked
    this.setState({ user }, () => this.updateHash())
  }

  isFormValid = () => {
    let { first_name, last_name, username, role } = this.state.user
    return first_name !== '' && last_name !== '' && username !== '' && role !== ''
  }

  handleConfirm = async () => {
    let errors = validateFields({ ...this.state.user })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let saved = await this.props.onSave(this.state.user)
      if (saved) this.props.closeForm()
    }
    this.setState({ validated: true })
  }

  render() {
    let { validated, user, show_password } = this.state
    let { id, username, first_name, last_name, email, role, enabled, password, confirm_password } = user
    let { roles, errors } = this.props
    let title = id
      ? t('users.user_form.edit_title', 'Modifica utente')
      : t('users.user_form.create_title', 'Crea utente')

    return (
      <>
        <h4 className="border-bottom text-primary p-1">{title}</h4>
        <div className="flex-fill position-relative overflow-auto container">
          {errors?.from_server?.length > 0 && (
            <Alert variant="danger">
              <Alert.Heading>Error:</Alert.Heading>
              <ul>
                {errors.from_server.map((err, i) => (
                  <li key={`err-${i}`}>{err.detail}</li>
                ))}
              </ul>
            </Alert>
          )}
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="users.user_form.name">Nome</Trans>
                </Form.Label>
                <span> *</span>
                <Form.Control
                  name="first_name"
                  placeholder={t('users.user_form.name', 'Nome')}
                  value={first_name}
                  onChange={this.handleChange}
                  isInvalid={validated && 'first_name' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="users.user_form.surname">Cognome</Trans>
                </Form.Label>
                <span> *</span>
                <Form.Control
                  name="last_name"
                  placeholder={t('users.user_form.surname', 'Cognome')}
                  value={last_name}
                  onChange={this.handleChange}
                  isInvalid={validated && 'last_name' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="users.user_form.username">Username</Trans>
                </Form.Label>
                <span> *</span>
                <Form.Control
                  name="username"
                  placeholder={t('users.user_form.username', 'Username')}
                  value={username}
                  onChange={this.handleChange}
                  isInvalid={validated && 'username' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="users.user_form.email">Email</Trans>
                </Form.Label>
                <Form.Control
                  name="email"
                  placeholder={t('users.user_form.email', 'Email')}
                  value={email}
                  onChange={this.handleChange}
                  isInvalid={validated && 'email' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="users.user_form.role">Ruolo</Trans>
                </Form.Label>
                <Form.Select value={role} onChange={this.handleSelectChange} isInvalid={validated && 'role' in errors}>
                  <option value="">{t('user.user_form.not_role', 'Nessun ruolo')}</option>
                  {roles &&
                    Object.entries(roles).map(role => (
                      <option key={`rol-opt-${role[0]}`} value={role[1]}>
                        {role[1]}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.role}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Check
                className="mt-5"
                type="switch"
                id="enabled"
                name="enabled"
                label="Abilitato"
                checked={enabled}
                onChange={this.handleIsEnabled}
              />
            </div>
            {!show_password && (
              <div className="row pt-4">
                <div className="col">
                  <Button variant="primary" onClick={() => this.setState({ show_password: !this.state.show_password })}>
                    <Trans i18nKey="common.button_change_password">Modifica password</Trans>
                  </Button>
                </div>
              </div>
            )}
            {show_password && (
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.password">Password</Trans>
                    </Form.Label>
                    <span> *</span>
                    <Form.Control
                      name="password"
                      placeholder={t('users.user_form.password', 'Password')}
                      value={password}
                      onChange={this.handleChange}
                      isInvalid={validated && 'password' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.confirm_password}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.confirm_password">Conferma password</Trans>
                    </Form.Label>
                    <span> *</span>
                    <Form.Control
                      name="confirm_password"
                      placeholder={t('users.user_form.confirm_password', 'Conferma password')}
                      value={confirm_password}
                      onChange={this.handleChange}
                      isInvalid={validated && 'confirm_password' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.confirm_password}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={!this.isFormValid() || this.initialHash === this.state.user_hash}
            onClick={this.handleConfirm}>
            <Trans i18nKey="common.button_confirm">Salva</Trans>
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            <Trans i18nKey="common.button_cancel">Annulla</Trans>
          </Button>
        </div>
      </>
    )
  }
}

export default UserForm
