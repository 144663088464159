import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form } from 'react-bootstrap'

import {
  toggleShowForm,
  toggleDisabledFilter,
  resetCurrentUser,
  setCurrentUser,
  getUsers,
  updateUser,
  createUser,
  deleteUser,
  resetPasswordUser,
  filterRoles,
  toggleUser,
} from '../../store/users'
import ResponsiveTable from '../system_wide/responsive_table'
import ActionsDropdown from '../system_wide/actions_dropdown'
import SearchBox from '../system_wide/search_box'
import UserForm from '../users/form'
import CheckboxFilter from '../system_wide/checkbox_filter'

const mstp = state => {
  let { users, total, roles, show_disabled, show_form, current_user, errors, filtered_roles } = state.users
  let { id: own_user_id } = state.userinfo
  return {
    users,
    total,
    roles,
    show_disabled,
    show_form,
    current_user,
    errors,
    own_user_id,
    filtered_roles,
  }
}

class UsersList extends React.Component {
  static defaultProps = {
    users: [],
    getData: () => {},
    columns: [],
    filter: null,
    roles: [],
    show_disabled: false,
  }

  state = {
    show_modal_toggle: false,
    show_modal_reset_password: false,
    show_modal_delete: false,
    user_to_reset_password: { id: '', name: '' },
    user_to_toggle: { id: '', name: '', enabled: null },
    user_to_delete: { id: '', name: '' },
    filter: '',
  }

  tableRef = null

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      disabled: rowData => rowData.id === this.props.own_user_id,
      onClick: rowData => {
        this.props.dispatch(setCurrentUser(rowData))
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-ban',
      text: 'Disattiva',
      visible: rowData => rowData.enabled === 1,
      disabled: rowData => rowData.id === this.props.own_user_id,
      onClick: ({ id, first_name, last_name, enabled }) => {
        this.setState({
          show_modal_toggle: true,
          user_to_toggle: { id, name: `${last_name} ${first_name}`, enabled },
        })
      },
    },
    {
      icon: 'fa-check',
      text: 'Riattiva',
      visible: rowData => rowData.enabled === 0,
      disabled: rowData => rowData.id === this.props.own_user_id,
      onClick: ({ id, first_name, last_name, enabled }) => {
        this.setState({
          show_modal_toggle: true,
          user_to_toggle: { id, name: `${first_name} ${last_name}`, enabled },
        })
      },
    },
    {
      icon: 'fa-key',
      text: 'Reset password',
      disabled: rowData => rowData.id === this.props.own_user_id,
      onClick: ({ id, first_name, last_name }) => {
        this.setState({
          show_modal_reset_password: true,
          user_to_reset_password: { id, name: `${last_name} ${first_name}` },
        })
      },
    },
    {
      icon: 'fa-trash',
      text: 'Elimina',
      disabled: rowData => rowData.id === this.props.own_user_id,
      onClick: ({ id, first_name, last_name }) => {
        this.setState({
          show_modal_delete: true,
          user_to_delete: { id, name: `${first_name} ${last_name}` },
        })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '4rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Username',
      className: 'd-lg-block flex-fill',
      style: { width: '10%', minWidth: '8rem' },
      sortable: true,
      data: 'username',
    },
    {
      title: 'Nome',
      className: 'd-lg-block flex-fill',
      style: { width: '10%', minWidth: '8rem' },
      sortable: true,
      data: 'first_name',
    },
    {
      title: 'Cognome',
      className: 'd-lg-block flex-fill',
      style: { width: '10%', minWidth: '8rem' },
      sortable: true,
      data: 'last_name',
    },
    {
      title: 'Ruolo',
      className: 'd-none d-lg-block flex-fill text-capitalize text-truncate',
      style: { width: '20%', minWidth: '10rem' },
      sortable: true,
      data: 'role',
    },
  ]

  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(getUsers({ offset: index - 1, limit: chunkSize, sortData, filter }))
  }

  onToggleForm = async () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentUser())
    }
    this.props.dispatch(toggleShowForm())
  }

  onCancelToggle = () => {
    this.setState({ show_modal_toggle: false, user_to_toggle: { id: '', name: '', enabled: null } })
  }
  onCancelDelete = () => {
    this.setState({ show_modal_delete: false, user_to_delete: { id: '', name: '' } })
  }
  onCancelResetPassword = () => {
    this.setState({
      show_modal_reset_password: false,
      user_to_reset_password: { id: '', name: '' },
    })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  onFilterChange = filter => this.setState({ filter })
  onChangeFilterDisabled = () => {
    this.props.dispatch(toggleDisabledFilter())
    this.tableRef.current.refreshData()
  }

  saveUser = async user => {
    try {
      if (user.id !== null) {
        await this.props.dispatch(updateUser(user))
        this.tableRef.current.refreshData()
      } else {
        await this.props.dispatch(createUser(user))
        this.tableRef.current.refreshData()
      }
      return true
    } catch (err) {
      return false
    }
  }
  onConfirmToggle = async () => {
    let user = this.state.user_to_toggle
    await this.props.dispatch(toggleUser(user.id))
    this.setState({ show_modal_toggle: false, user_to_toggle: { id: '', name: '', enabled: null } })
    this.tableRef.current.refreshData()
  }
  onConfirmDelete = async () => {
    let user = this.state.user_to_delete
    await this.props.dispatch(deleteUser(user.id))
    this.setState({ show_modal_delete: false, user_to_delete: { id: '', name: '' } })
    this.tableRef.current.refreshData()
  }
  onConfirmResetPassword = async () => {
    let user = this.state.user_to_reset_password
    await this.props.dispatch(resetPasswordUser(user))
    this.setState({ show_modal_reset_password: false, user_to_reset_password: { id: '', name: '' } })
  }

  rowClasses = ({ enabled }) => (enabled === 0 ? 'bg-warning bg-gradient bg-opacity-50' : '')
  onFilterRoles = roles => {
    this.props.dispatch(filterRoles(roles))
    this.tableRef.current.refreshData()
  }

  render() {
    let { users, total, current_user, show_form, show_disabled, errors, filtered_roles, roles } = this.props
    if (!users) return null
    let rolesOptions = roles.map(v => ({ id: v, name: v }))

    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-1 pe-lg-1 pt-2 zi-1 h-100 w-100">
        <div className="d-flex flex-column flex-fill overflow-auto">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <Button onClick={this.onToggleForm} disabled={this.props.show_form}>
                  <i className="fas fa-plus fa-fw text-start" />
                  Aggiungi utente
                </Button>
              </div>
              <div className="flex-fill">
                <SearchBox
                  value={this.state.filter}
                  onChange={this.onFilterChange}
                  disabled={this.props.show_form}
                  className="float-md-end ms-3"
                />
                <CheckboxFilter
                  className="float-md-end ms-2"
                  label={'Ruoli'}
                  items={rolesOptions}
                  filteredItems={filtered_roles}
                  onChangeFilter={this.onFilterRoles}
                />
                <Form.Check
                  className="float-md-end mt-2"
                  type="switch"
                  id="deactivated-switch"
                  label="Visualizza disabilitati"
                  value={show_disabled}
                  onChange={this.onChangeFilterDisabled}
                />
              </div>
            </div>
          </div>
          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill border"
            rowClasses={this.rowClasses}
            menuWidth="45rem"
            data={users}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            showMenu={show_form}
            menuContent={UserForm}
            menuContentProps={{
              user: current_user,
              roles: this.props.roles,
              closeForm: this.onToggleForm,
              onSave: this.saveUser,
              errors,
              dispatch: this.props.dispatch,
            }}
            filter={this.state.filter}
          />
        </div>
        <Modal backdrop="static" centered show={this.state.show_modal_toggle} onHide={this.onCancelToggle}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.user_to_toggle.enabled === 1 ? 'Disattiva' : 'Attiva'} utente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere sul <b>{this.state.user_to_toggle.name}</b> click su Prosegui
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancelToggle} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmToggle} variant="warning">
              Prosegui
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal backdrop="static" centered show={this.state.show_modal_delete} onHide={this.onCancelDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Elimina utente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere ad eliminiare l'utente <b>{this.state.user_to_delete.name}</b> click su Elimina
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancelDelete} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          backdrop="static"
          centered
          show={this.state.show_modal_reset_password}
          onHide={this.onCancelResetPassword}
        >
          <Modal.Header closeButton>
            <Modal.Title>Reset password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere al reset della password per l'utente <b>{this.state.user_to_reset_password.name}</b> click
              su Reset password
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancelResetPassword} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmResetPassword} variant="success" disabled={false}>
              Reset password
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default connect(mstp)(UsersList)
