import React from 'react'

class Travaso extends React.Component {
  render() {
    let { c, operators } = this.props
    let user = operators.find(u => u.id === c.user_id)
    return (
      <div className="p-3 overflow-y" style={{ maxHeight: 580 }}>
        <div className="row">
          <div className="col-5">
            <div className="row text-muted"> Effettuato in data: </div>
            <div className="row fw-bold">{c.created_at}</div>
          </div>
          <div className="col-5">
            <div className="row text-muted">Effettuato dall'operatore:</div>
            <div className="row fw-bold">{user.fullname}</div>
          </div>
        </div>
        <div className="row pt-3">Serbatoi di partenza:</div>
        <ul className="pt-2">
          {c.from_containers.map(s => (
            <li>
              <b className="pe-2">{s.name}</b> == Contenuto:
              <b className="ps-2">
                {s.content_name} - {s.content_status} - {s.selQty} hl
              </b>
            </li>
          ))}
        </ul>
        <div className="row pt-3">Serbatoi di destinazione:</div>
        <ul className="pt-2">
          {c.to_containers.map(s => (
            <li>
              <b className="pe-2">{s.name}</b> ==
              <b className="ps-2">{s.selQty} hl</b>
            </li>
          ))}
        </ul>
        <div className="row pt-3 pb-1">
          Stato del contenuto: <b>{c.status}</b>
        </div>
        <div className="row pt-3 pb-3">
          Colore: <b>{c.color}</b>
        </div>
        <div className="row text-muted pt-4">NOTE:</div>
        <div className="row">{c.note}</div>
      </div>
    )
  }
}

export default Travaso
