import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../axios'
import config from '../config'
import { addSuccessNotification, addErrorNotification } from './notifications'

const initialState = {
  users: {},
  total: 0,
  loading: false,
  roles: ['Admin', 'Operatore', 'Amministrazione', 'Sistema'],
  filtered_roles: ['Admin', 'Operatore', 'Amministrazione', 'Sistema'],
  show_disabled: false,
  show_form: false,
  current_user: {},
  errors: {},
}

export const getUsers = createAsyncThunk('users/get_users', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let state = thunkApi.getState().users
    let url = `${config.SERVER_API_URL}/users?`
    if (filter) url = url + `&q=${encodeURIComponent(filter)}`
    if (state.show_disabled) url = url + `&disabled=true`
    if (limit) url = url + `&_end=${encodeURIComponent(limit)}`
    if (offset) url = url + `&_start=${encodeURIComponent(offset)}`
    if (sort_direction) url = url + `&_order=${encodeURIComponent(sort_direction)}`
    if (sort_column) url = url + `&_sort=${encodeURIComponent(sort_column)}`
    if (state.roles && state.filtered_roles.length < state.roles.length)
      url = url + `&roles=${encodeURIComponent(state.filtered_roles.join(','))}`

    let response = await axios({
      url,
      method: 'GET',
    })
    let total = parseInt(response.headers['x-total-count'])
    return { users: response.data, total }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})
export const createUser = createAsyncThunk('users/create_user', async (user, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/users`,
      method: 'POST',
      data: user,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { user: response.data }
  } catch (err) {
    thunkApi.dispatch(updateErrors({ errors: err.response.data.errors }))
    thunkApi.dispatch(addErrorNotification("Impossibile salvare l'utente!"))
    return thunkApi.rejectWithValue({})
  }
})

export const updateUser = createAsyncThunk('users/update_user', async (user, thunkApi) => {
  try {
    if (!user.id) throw Error('Impossibile salvare!')
    let response = await axios({
      url: `${config.SERVER_API_URL}/users/${user.id}`,
      method: 'PUT',
      data: user,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { user: response.data }
  } catch (err) {
    thunkApi.dispatch(updateErrors({ errors: err.response.data.errors }))
    thunkApi.dispatch(addErrorNotification("Impossibile salvare l'utente!"))
    return thunkApi.rejectWithValue({})
  }
})

export const resetPasswordUser = createAsyncThunk('users/reset_password', async (user, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/users/${user.id}/reset_password`,
      method: 'POST',
    })
    thunkApi.dispatch(addSuccessNotification('Reset password inviata per email'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile inviare l'email di reset password"))
    return thunkApi.rejectWithValue()
  }
})

export const toggleUser = createAsyncThunk('users/toggle_user', async (user_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/users/${user_id}/toggle`,
      method: 'GET',
    })
    thunkApi.dispatch(addSuccessNotification('Utente modificato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile modificare l'utente"))
    return thunkApi.rejectWithValue()
  }
})
export const deleteUser = createAsyncThunk('users/delete_user', async (user_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/users/${user_id}`,
      method: 'DELETE',
    })
    thunkApi.dispatch(addSuccessNotification('Utente eliminato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile eliminare l'utente"))
    return thunkApi.rejectWithValue()
  }
})

export const userinfoSlice = createSlice({
  name: 'users',
  initialState,

  reducers: {
    setLoading: state => {
      state.loading = true
    },
    filterRoles: (state, action) => {
      state.filtered_roles = action.payload
    },
    toggleDisabledFilter: state => {
      state.show_disabled = !state.show_disabled
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentUser: state => {
      state.current_user = {}
    },
    updateErrors: (state, action) => {
      state.errors = action.payload.errors
    },
    setCurrentUser: (state, action) => {
      state.current_user = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        let { users, total } = action.payload
        state.users = users
        state.total = total
        state.loading = false
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.users = []
        state.total = 0
        state.loading = false
      })
      .addCase(updateUser.rejected, () => {
        throw new Error()
      })
      .addCase(createUser.rejected, () => {
        throw new Error()
      })
  },
})

export const {
  setLoading,
  filterRoles,
  toggleDisabledFilter,
  resetCurrentUser,
  toggleShowForm,
  updateErrors,
  setCurrentUser,
} = userinfoSlice.actions
export default userinfoSlice.reducer
