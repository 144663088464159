import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import cookies from 'browser-cookies'
import axios from 'axios'
import myAxios from '../axios'
import config from '../config'
import { addErrorNotification } from './notifications'

const initialState = {
  user: {},
  access_token: null,
  loading: false,
  errors: '',
}

export const login = createAsyncThunk('userinfo/login', async ({ username, password }, thunkApi) => {
  try {
    thunkApi.dispatch(setLoading())
    let response = await axios({
      url: `${config.SERVER_API_URL}/login`,
      method: 'POST',
      data: { username, password },
    })
    if (response.data.role !== 'Admin') throw new Error('Login non abilitato')
    cookies.set('palama-admin', `base_laravel-token=${response.data.access_token}`, { path: '/' })
    let { access_token, ...rest } = response.data
    return { access_token, user: rest }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})

export const getUserInfo = createAsyncThunk('userinfo/get_info', async (user_id, thunkApi) => {
  try {
    let response = await myAxios({
      url: `${config.SERVER_API_URL}/users/my_info`,
      method: 'GET',
    })
    return { user: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification("Impossibile recuperare info dell'utente!"))
    return thunkApi.rejectWithValue({})
  }
})

export const userinfoSlice = createSlice({
  name: 'userinfo',
  initialState,

  reducers: {
    setLoading: (state, action) => {
      state.loading = true
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload.user
        state.access_token = action.payload.access_token
        state.loading = false
        state.errors = ''
      })
      .addCase(login.rejected, (state, action) => {
        state.user = {}
        state.access_token = null
        state.loading = false
        state.errors = 'Username o password errati'
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.user = action.payload.user
        state.loading = false
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.user = {}
        state.loading = false
      })
  },
})

export const { setLoading } = userinfoSlice.actions
export default userinfoSlice.reducer
