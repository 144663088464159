import React from 'react'
import { connect } from 'react-redux'

import { getContainers } from 'store/containers'
import {
  getHistories,
  setCurrentOp,
  toggleShowForm,
  resetCurrentOp,
  filterContainers,
  filterTask,
  getOperators,
  tasksLabel,
} from 'store/histories'
import ResponsiveTable from '../system_wide/responsive_table'
import ActionsDropdown from '../system_wide/actions_dropdown'
import SearchBox from '../system_wide/search_box'
import HistoryForm from './form'
import CheckboxFilter from '../system_wide/checkbox_filter'

const mstp = state => {
  let { histories, total, show_form, current_op, errors, filtered_containers, filtered_task, operators } =
    state.histories
  let { containers } = state.containers
  return {
    histories,
    containers,
    total,
    show_form,
    current_op,
    errors,
    filtered_containers,
    filtered_task,
    operators,
  }
}

class HistoriesList extends React.Component {
  static defaultProps = {
    histories: [],
    getData: () => {},
    columns: [],
    filter: null,
  }

  state = {
    filter: '',
  }

  tableRef = null

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  async componentDidMount() {
    await this.props.dispatch(getContainers({ offset: 0, limit: 100 }))
    await this.props.dispatch(getOperators())
  }

  actions = [
    {
      icon: 'fa-eye',
      text: 'Visualizza',
      onClick: rowData => {
        this.props.dispatch(setCurrentOp(rowData))
        this.props.dispatch(toggleShowForm())
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }
  renderDates = ({ rowData: { created_at } }) => {
    let data_creazione = new Date(created_at)
    return data_creazione.toLocaleDateString()
  }
  renderTask = ({ rowData: { task } }) => {
    return task.replace('_', ' ')
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '5%', minWidth: '4rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Serbatoio',
      className: 'd-lg-block flex-fill',
      style: { width: '15%', minWidth: '4rem' },
      sortable: true,
      data: 'container_name',
    },
    {
      title: 'Task',
      className: 'd-lg-block flex-fill text-capitalize',
      style: { width: '20%', minWidth: '8rem' },
      sortable: true,
      data: this.renderTask,
    },
    {
      title: 'Stadio di lavorazione',
      className: 'd-lg-block flex-fill',
      style: { width: '20%', minWidth: '8rem' },
      sortable: true,
      data: 'branch',
    },
    {
      title: 'Contenuto',
      className: 'd-lg-block flex-fill',
      style: { width: '20%', minWidth: '8rem' },
      sortable: false,
      data: 'content_name',
    },
    {
      title: 'Data',
      className: 'd-lg-block flex-fill',
      style: { width: '20%', minWidth: '8rem' },
      sortable: true,
      data: this.renderDates,
      sortingKey: 'created_at',
    },
  ]

  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(getHistories({ offset: index - 1, limit: chunkSize, sortData, filter }))
  }

  onToggleForm = async () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentOp())
    }
    this.props.dispatch(toggleShowForm())
  }

  onFilterContainer = containers => {
    this.props.dispatch(filterContainers(containers))
    this.tableRef.current.refreshData()
  }
  onFilterTask = tasks => {
    this.props.dispatch(filterTask(tasks))
    this.tableRef.current.refreshData()
  }

  onFilterChange = filter => this.setState({ filter })

  rowClasses = ({ enabled }) => (enabled === 0 ? 'bg-warning bg-gradient bg-opacity-50' : '')

  render() {
    let { histories, total, current_op, show_form, errors, containers, filtered_containers, filtered_task } = this.props
    if (!histories) return null

    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-1 pe-lg-1 pt-2 zi-1 h-100 w-100">
        <div className="d-flex flex-column flex-fill overflow-auto">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill">
                <SearchBox
                  value={this.state.filter}
                  onChange={this.onFilterChange}
                  disabled={this.props.show_form}
                  className="float-md-end ms-3"
                />
                <CheckboxFilter
                  className="float-md-end ms-2 filter-containers"
                  style={{ maxHeight: 250, overflow: 'auto' }}
                  label={'Serbatoio'}
                  items={containers}
                  multi={false}
                  filteredItems={filtered_containers}
                  onChangeFilter={this.onFilterContainer}
                />
                <CheckboxFilter
                  className="float-md-end ms-2 filter-containers"
                  style={{ maxHeight: 250, overflow: 'auto' }}
                  label={'Task'}
                  items={tasksLabel}
                  multi={false}
                  filteredItems={filtered_task}
                  onChangeFilter={this.onFilterTask}
                />
              </div>
            </div>
          </div>
          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill border"
            rowClasses={this.rowClasses}
            menuWidth="45rem"
            data={histories}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            showMenu={show_form}
            menuContent={HistoryForm}
            menuContentProps={{
              operation: current_op,
              closeForm: this.onToggleForm,
              errors,
              dispatch: this.props.dispatch,
              operators: this.props.operators,
            }}
            filter={this.state.filter}
          />
        </div>
      </div>
    )
  }
}

export default connect(mstp)(HistoriesList)
