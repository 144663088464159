import React from 'react'
import { washingOptions } from 'store/containers'

class Lavaggio extends React.Component {
  render() {
    let { c, operators } = this.props
    let user = operators.find(u => u.id === c.user_id)
    let washing_status_color = washingOptions.find(w => w.id === c.washing_status)?.color ?? 'white'
    console.log(c)
    return (
      <div className="p-3 overflow-y" style={{ maxHeight: 580 }}>
        <div className="row">
          <div className="col-5">
            <div className="row text-muted"> Effettuato in data: </div>
            <div className="row fw-bold">{c.created_at}</div>
          </div>
          <div className="col-5">
            <div className="row text-muted">Effettuato dall'operatore:</div>
            <div className="row fw-bold">{user.fullname}</div>
          </div>
        </div>
        <div className="row pt-3">Serbatoi di partenza:</div>
        <ul className="pt-2">
          {c.containers.map(s => (
            <li>
              <b className="pe-2">{s.name}</b> == Contenuto:
              <b className="ps-2">
                {s.content_name} - {s.content_status} - {s.quantity} hl
              </b>
            </li>
          ))}
        </ul>
        <div className="pt-3 pb-3">Lavaggio:</div>
        <div className="d-flex">
          <i className="fas fa-square fa-fw text-start" style={{ color: washing_status_color }} />
          <b>{c.washing_status}</b>
        </div>
        <div className="row text-muted pt-4">NOTE:</div>
        <div className="row">{c.note}</div>
      </div>
    )
  }
}

export default Lavaggio
