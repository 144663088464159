import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../axios'
import config from '../config'
import { addSuccessNotification, addErrorNotification } from './notifications'

export const branchOptions = [
  { id: 'Vino', name: 'Vino' },
  { id: 'Mosto', name: 'Mosto' },
]

export const actionsOptions = [
  { id: 'Chiarifica', name: 'Chiarifica' },
  { id: 'Filtrazione', name: 'Filtrazione' },
  { id: 'Aggiunte enologiche', name: 'Aggiunte enologiche' },
]
const initialState = {
  additions: [],
  categories: [],
  total: 0,
  current_page: 1,
  loading: false,
  show_disabled: false,
  show_form: false,
  current_addition: {},
  filtered_branch: [],
  filtered_actions: [],
  errors: {},
}
export const getCategories = createAsyncThunk('additions/get_categories', async (_, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/addition_categories`
    let response = await axios({ url, method: 'GET' })
    return response.data
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})

export const getAdditions = createAsyncThunk('additions/get_additions', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let current_page = parseInt(offset + 1 / limit) + 1 || 1
    let state = thunkApi.getState().additions
    let url = `${config.SERVER_API_URL}/additions?`
    if (filter) url = url + `&q=${encodeURIComponent(filter)}`
    if (state.show_disabled) url = url + `&disabled=true`
    if (limit) url = url + `&_end=${encodeURIComponent(limit)}`
    if (offset) url = url + `&_start=${encodeURIComponent(offset)}`
    if (sort_direction) url = url + `&_order=${encodeURIComponent(sort_direction)}`
    if (sort_column) url = url + `&_sort=${encodeURIComponent(sort_column)}`
    if (state.filtered_branch.length > 0) url = url + `&branch=${encodeURIComponent(state.filtered_branch.join(','))}`
    if (state.filtered_actions.length > 0)
      url = url + `&actions=${encodeURIComponent(state.filtered_actions.join(','))}`

    let response = await axios({
      url,
      method: 'GET',
    })
    let total = parseInt(response.headers['x-total-count'])
    return { additions: response.data, total, current_page }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})
export const createAddition = createAsyncThunk('additions/create_addition', async (addition, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/additions`,
      method: 'POST',
      data: addition,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { addition: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification("Impossibile salvare l'elemento!"))
    return thunkApi.rejectWithValue({})
  }
})

export const createCategory = createAsyncThunk('additions/create_category', async (category, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/addition_categories`,
      method: 'POST',
      data: { name: category },
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return true
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification("Impossibile salvare l'elemento!"))
    return thunkApi.rejectWithValue({})
  }
})

export const updateAddition = createAsyncThunk('additions/update_addition', async (addition, thunkApi) => {
  try {
    if (!addition.id) throw Error('Impossibile salvare!')
    let response = await axios({
      url: `${config.SERVER_API_URL}/additions/${addition.id}`,
      method: 'PUT',
      data: addition,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { addition: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification("Impossibile salvare l'elemento!"))
    return thunkApi.rejectWithValue({})
  }
})

export const resetPasswordAddition = createAsyncThunk('additions/reset_password', async (addition, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/additions/${addition.id}/reset_password`,
      method: 'POST',
    })
    thunkApi.dispatch(addSuccessNotification('Reset password inviata per email'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile inviare l'email di reset password"))
    return thunkApi.rejectWithValue()
  }
})

export const toggleAddition = createAsyncThunk('additions/toggle_addition', async (addition_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/additions/${addition_id}/toggle`,
      method: 'GET',
    })
    thunkApi.dispatch(addSuccessNotification('Elemento modificato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile modificare l'elemento"))
    return thunkApi.rejectWithValue()
  }
})
export const deleteAddition = createAsyncThunk('additions/delete_addition', async (addition_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/additions/${addition_id}`,
      method: 'DELETE',
    })
    thunkApi.dispatch(addSuccessNotification('Elemento eliminato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile eliminare l'elemento"))
    return thunkApi.rejectWithValue()
  }
})
export const deleteCategory = createAsyncThunk('additions/delete_category', async (category_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/addition_categories/${category_id}`,
      method: 'DELETE',
    })
    thunkApi.dispatch(addSuccessNotification('Elemento eliminato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification('Impossibile eliminare il dato'))
    return thunkApi.rejectWithValue()
  }
})

export const additioninfoSlice = createSlice({
  name: 'additions',
  initialState,

  reducers: {
    setLoading: state => {
      state.loading = true
    },
    filterBranch: (state, action) => {
      state.filtered_branch = action.payload
      state.additions = []
      state.current_page = 1
    },
    filterActions: (state, action) => {
      state.filtered_actions = action.payload
      state.additions = []
      state.current_page = 1
    },
    toggleDisabledFilter: state => {
      state.show_disabled = !state.show_disabled
      state.additions = []
      state.current_page = 1
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentAddition: state => {
      state.current_addition = {}
    },
    updateErrors: (state, action) => {
      state.errors = action.payload.errors
    },
    setCurrentAddition: (state, action) => {
      state.current_addition = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAdditions.fulfilled, (state, action) => {
        let { additions, total, current_page } = action.payload
        state.additions = current_page === 1 ? additions : [...state.additions, ...additions]
        state.total = total
        state.current_page = current_page
        state.loading = false
      })
      .addCase(getAdditions.rejected, state => {
        state.additions = []
        state.total = 0
        state.current_page = 1
        state.loading = false
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload
      })
      .addCase(getCategories.rejected, state => {
        state.categories = []
      })
      .addCase(updateAddition.rejected, () => {
        throw new Error()
      })
      .addCase(createAddition.rejected, () => {
        throw new Error()
      })
  },
})

export const {
  setLoading,
  toggleDisabledFilter,
  resetCurrentAddition,
  toggleShowForm,
  updateErrors,
  setCurrentAddition,
  filterBranch,
  filterActions,
} = additioninfoSlice.actions
export default additioninfoSlice.reducer
