import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../axios'
import config from '../config'
import { addSuccessNotification, addErrorNotification } from './notifications'

export const branchOptions = [
  { id: 'Vino', name: 'Vino' },
  { id: 'Mosto', name: 'Mosto' },
]

const initialState = {
  analyses: [],
  total: 0,
  current_page: 1,
  loading: false,
  show_disabled: false,
  show_form: false,
  current_analyses: {},
  filtered_branch: [],
  errors: {},
}

export const getAnalysess = createAsyncThunk('analyses/get_analyses', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let current_page = parseInt(offset + 1 / limit) + 1 || 1
    let state = thunkApi.getState().analyses
    let url = `${config.SERVER_API_URL}/analyses?`
    if (filter) url = url + `&q=${encodeURIComponent(filter)}`
    if (state.show_disabled) url = url + `&disabled=true`
    if (limit) url = url + `&_end=${encodeURIComponent(limit)}`
    if (offset) url = url + `&_start=${encodeURIComponent(offset)}`
    if (sort_direction) url = url + `&_order=${encodeURIComponent(sort_direction)}`
    if (sort_column) url = url + `&_sort=${encodeURIComponent(sort_column)}`
    if (state.filtered_branch.length > 0) url = url + `&branch=${encodeURIComponent(state.filtered_branch.join(','))}`

    let response = await axios({
      url,
      method: 'GET',
    })
    let total = parseInt(response.headers['x-total-count'])
    return { analyses: response.data, total, current_page }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})
export const createAnalyses = createAsyncThunk('analyses/create_analyses', async (analyses, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/analyses`,
      method: 'POST',
      data: analyses,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { analyses: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification('Impossibile salvare la provenienza!'))
    return thunkApi.rejectWithValue({})
  }
})

export const updateAnalyses = createAsyncThunk('analyses/update_analyses', async (analyses, thunkApi) => {
  try {
    if (!analyses.id) throw Error('Impossibile salvare!')
    let response = await axios({
      url: `${config.SERVER_API_URL}/analyses/${analyses.id}`,
      method: 'PUT',
      data: analyses,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { analyses: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification('Impossibile salvare la provenienza!'))
    return thunkApi.rejectWithValue({})
  }
})

export const toggleAnalyses = createAsyncThunk('analyses/toggle_analyses', async (analyses_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/analyses/${analyses_id}/toggle`,
      method: 'GET',
    })
    thunkApi.dispatch(addSuccessNotification('Elemento modificato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile modificare l'elemento"))
    return thunkApi.rejectWithValue()
  }
})
export const deleteAnalyses = createAsyncThunk('analyses/delete_analyses', async (analyses_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/analyses/${analyses_id}`,
      method: 'DELETE',
    })
    thunkApi.dispatch(addSuccessNotification('Elemento eliminato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile eliminare l'elemento"))
    return thunkApi.rejectWithValue()
  }
})

export const analysesinfoSlice = createSlice({
  name: 'analyses',
  initialState,

  reducers: {
    setLoading: state => {
      state.loading = true
    },
    filterBranch: (state, action) => {
      state.filtered_branch = action.payload
      state.analyses = []
      state.current_page = 1
    },
    filterActions: (state, action) => {
      state.filtered_actions = action.payload
      state.analyses = []
      state.current_page = 1
    },
    toggleDisabledFilter: state => {
      state.show_disabled = !state.show_disabled
      state.analyses = []
      state.current_page = 1
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentAnalyses: state => {
      state.current_analyses = {}
    },
    updateErrors: (state, action) => {
      state.errors = action.payload.errors
    },
    setCurrentAnalyses: (state, action) => {
      state.current_analyses = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAnalysess.fulfilled, (state, action) => {
        let { analyses, total, current_page } = action.payload
        state.analyses = current_page === 1 ? analyses : [...state.analyses, ...analyses]
        state.total = total
        state.current_page = current_page
        state.loading = false
      })
      .addCase(getAnalysess.rejected, (state, action) => {
        state.analyses = []
        state.total = 0
        state.current_page = 1
        state.loading = false
      })
      .addCase(updateAnalyses.rejected, () => {
        throw new Error()
      })
      .addCase(createAnalyses.rejected, () => {
        throw new Error()
      })
  },
})

export const {
  setLoading,
  toggleDisabledFilter,
  resetCurrentAnalyses,
  toggleShowForm,
  updateErrors,
  setCurrentAnalyses,
  filterBranch,
  filterActions,
} = analysesinfoSlice.actions
export default analysesinfoSlice.reducer
