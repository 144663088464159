import cookies from 'browser-cookies'

export function getToken() {
  const access_token = cookies.get('palama-admin')
  return access_token
}

export function generateRandomPassword() {
  var chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  var passwordLength = 12
  var password = ''
  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length)
    password += chars.substring(randomNumber, randomNumber + 1)
  }
  return password
}

export function makeid(length) {
  let result = ''
  const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}
