const pages = [
  { name: 'Home', icon: 'fa-home', pathname: '/', in_home: false },
  { name: 'Utenti', icon: 'fa-users', pathname: '/users', in_home: true },
  { name: 'Serbatoi', icon: 'fa-database', pathname: '/containers', in_home: true },
  { name: 'Provenienza / Produttore', icon: 'fa-truck-moving', pathname: '/origins', in_home: true },
  { name: 'Aggiunte enologiche', icon: 'fa-circle-nodes', pathname: '/additions', in_home: true },
  { name: 'Analisi', icon: 'fa-vials', pathname: '/analyses', in_home: true },
  { name: 'Varietà', icon: 'fa-shapes', pathname: '/kinds', in_home: true },
  { name: 'Tipo vino', icon: 'fa-wine-bottle', pathname: '/typologies', in_home: true },
  { name: 'Device', icon: 'fa-calculator', pathname: '/devices', in_home: true },
  { name: 'Operazioni', icon: 'fa-list-check', pathname: '/histories', in_home: true },
]

export default pages
