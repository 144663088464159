import MassaSummary from 'components/common/MassaSummary'
import React from 'react'

class Massa extends React.Component {
  render() {
    let { c, operators } = this.props
    let user = operators.find(u => u.id === c.user_id)
    return (
      <div className="p-3 overflow-y" style={{ maxHeight: 580 }}>
        <div className="row">
          <div className="col-5">
            <div className="row text-muted">Effettuato in data: </div>
            <div className="row fw-bold">{c.created_at}</div>
          </div>
          <div className="col-5">
            <div className="row text-muted">Effettuato dall'operatore:</div>
            <div className="row fw-bold">{user.fullname}</div>
          </div>
        </div>
        <div className="row pt-3">Serbatoi di partenza:</div>
        <ul className="pt-2">
          {c.from_containers.map(s => (
            <li>
              <b className="pe-2">{s.name}</b> ==
              <b className="ps-2">{s.selQty} hl</b>
            </li>
          ))}
        </ul>
        <div className="row pt-3">Serbatoi di destinazione:</div>
        <ul className="pt-2">
          {c.to_containers.map(s => (
            <li>
              <b className="pe-2">{s.name}</b> ==
              <b className="ps-2">{s.selQty} hl</b>
            </li>
          ))}
        </ul>
        <MassaSummary containers={c.from_containers} />
        <div className="row pt-3">Nuovo contenuto:</div>
        <div className="d-flex pt-3">
          <div className="col-5">
            Nome: <b className="ps-1">{c.name}</b>
          </div>
          <div className="col-5">
            Varieta': <b className="ps-1">{c.kind.join(',')}</b>
          </div>
        </div>
        <div className="d-flex pt-1">
          <div className="col-5">
            Colore: <b className="ps-1">{c.color}</b>
          </div>
          <div className="col-8">
            Stato: <b className="ps-1">{c.status}</b>
          </div>
        </div>
        <div className="d-flex pt-1">
          <div className="col-5">
            Tipologia: <b className="ps-1">{c.tipology}</b>
          </div>
          <div className="col-8">
            Annata: <b className="ps-1">{c.year}</b>
          </div>
        </div>
        <div className="row text-muted pt-4">NOTE:</div>
        <div className="row">{c.note}</div>
      </div>
    )
  }
}

export default Massa
