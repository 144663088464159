import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../axios'
import config from '../config'
import { addSuccessNotification, addErrorNotification } from './notifications'

const initialState = {
  kinds: {},
  total: 0,
  loading: false,
  show_disabled: false,
  show_form: false,
  current_kind: {},
  errors: {},
}

export const getKinds = createAsyncThunk('kinds/get_kinds', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let state = thunkApi.getState().kinds
    let url = `${config.SERVER_API_URL}/kinds?`
    if (filter) url = url + `&q=${encodeURIComponent(filter)}`
    if (state.show_disabled) url = url + `&disabled=true`
    if (limit) url = url + `&_end=${encodeURIComponent(limit)}`
    if (offset) url = url + `&_start=${encodeURIComponent(offset)}`
    if (sort_direction) url = url + `&_order=${encodeURIComponent(sort_direction)}`
    if (sort_column) url = url + `&_sort=${encodeURIComponent(sort_column)}`

    let response = await axios({
      url,
      method: 'GET',
    })
    let total = parseInt(response.headers['x-total-count'])
    return { kinds: response.data, total }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})
export const createKind = createAsyncThunk('kinds/create_kind', async (kind, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/kinds`,
      method: 'POST',
      data: kind,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { kind: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification("Impossibile salvare la varita'!"))
    return thunkApi.rejectWithValue({})
  }
})

export const updateKind = createAsyncThunk('kinds/update_kind', async (kind, thunkApi) => {
  try {
    if (!kind.id) throw Error('Impossibile salvare!')
    let response = await axios({
      url: `${config.SERVER_API_URL}/kinds/${kind.id}`,
      method: 'PUT',
      data: kind,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { kind: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification("Impossibile salvare la varita'!"))
    return thunkApi.rejectWithValue({})
  }
})

export const resetPasswordKind = createAsyncThunk('kinds/reset_password', async (kind, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/kinds/${kind.id}/reset_password`,
      method: 'POST',
    })
    thunkApi.dispatch(addSuccessNotification('Reset password inviata per email'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile inviare l'email di reset password"))
    return thunkApi.rejectWithValue()
  }
})

export const toggleKind = createAsyncThunk('kinds/toggle_kind', async (kind_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/kinds/${kind_id}/toggle`,
      method: 'GET',
    })
    thunkApi.dispatch(addSuccessNotification('Utente modificato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile modificare la varita'"))
    return thunkApi.rejectWithValue()
  }
})
export const deleteKind = createAsyncThunk('kinds/delete_kind', async (kind_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/kinds/${kind_id}`,
      method: 'DELETE',
    })
    thunkApi.dispatch(addSuccessNotification('Utente eliminato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile eliminare la varita'"))
    return thunkApi.rejectWithValue()
  }
})

export const kindinfoSlice = createSlice({
  name: 'kinds',
  initialState,

  reducers: {
    setLoading: state => {
      state.loading = true
    },
    toggleDisabledFilter: state => {
      state.show_disabled = !state.show_disabled
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentKind: state => {
      state.current_kind = {}
    },
    updateErrors: (state, action) => {
      state.errors = action.payload.errors
    },
    setCurrentKind: (state, action) => {
      state.current_kind = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getKinds.fulfilled, (state, action) => {
        let { kinds, total } = action.payload
        state.kinds = kinds
        state.total = total
        state.loading = false
      })
      .addCase(getKinds.rejected, (state, action) => {
        state.kinds = []
        state.total = 0
        state.loading = false
      })
      .addCase(updateKind.rejected, () => {
        throw new Error()
      })
      .addCase(createKind.rejected, () => {
        throw new Error()
      })
  },
})

export const { setLoading, toggleDisabledFilter, resetCurrentKind, toggleShowForm, updateErrors, setCurrentKind } =
  kindinfoSlice.actions
export default kindinfoSlice.reducer
