import React from 'react'
import { Button, Form } from 'react-bootstrap'
import i18next from 'i18next'
import hash from 'object-hash'
import { Trans } from 'react-i18next'

import validateFields from 'lib/validators/device'
import { updateErrors } from 'store/devices'
import { makeid } from 'utils'

const { t } = i18next

const initialState = {
  id: null,
  name: '',
  code: '',
  enabled: true,
}

class DeviceForm extends React.Component {
  static defaultProps = {
    closeForm: () => {},
    onSave: () => {},
    errors: {},
    device: initialState,
  }
  state = {
    device: initialState,
    device_hash: '',
  }

  setDevice = () => {
    let device = {
      ...this.state.device,
      ...this.props.device,
    }
    if (device.code === '') device.code = makeid(10)
    let device_hash = hash(device)
    this.setState({ device, device_hash })
    this.initialHash = hash(device)
  }
  componentDidMount() {
    this.setDevice()
  }

  componentDidUpdate = prevProps => {
    if (prevProps.device.id !== this.props.device.id) {
      this.setDevice()
    }
  }

  updateHash = () => {
    let { device } = this.state
    this.setState({ device_hash: hash(device) })
  }

  handleChange = ({ target: { name, value } }) => {
    let device = { ...this.state.device }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    device[name] = value
    this.setState({ device }, () => this.updateHash())
  }
  handleIsEnabled = e => {
    let device = { ...this.state.device }
    device['enabled'] = e.target.checked
    this.setState({ device }, () => this.updateHash())
  }

  isFormValid = () => {
    let { name } = this.state.device
    return name !== ''
  }

  handleConfirm = async () => {
    let errors = validateFields({ ...this.state.device })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let saved = await this.props.onSave(this.state.device)
      if (saved) this.props.closeForm()
    }
    this.setState({ validated: true })
  }

  render() {
    let { validated, device } = this.state
    let { id, name, code, enabled } = device
    let { errors } = this.props
    let title = id
      ? t('devices.device_form.edit_title', 'Modifica device')
      : t('devices.device_form.create_title', 'Crea device')

    console.log(errors)
    return (
      <>
        <h4 className="border-bottom text-primary p-1">{title}</h4>
        <div className="flex-fill position-relative overflow-auto container">
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="devices.device_form.name">Nome</Trans>
                </Form.Label>
                <span> *</span>
                <Form.Control
                  name="name"
                  placeholder={t('devices.device_form.name', 'Nome')}
                  value={name}
                  onChange={this.handleChange}
                  isInvalid={validated && 'first_name' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Check
                className="mt-5"
                type="switch"
                id="enabled"
                name="enabled"
                label="Abilitato"
                checked={enabled}
                onChange={this.handleIsEnabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="devices.device_form.code">Chiave</Trans>
                </Form.Label>
                <span> *</span>
                <Form.Control
                  name="code"
                  placeholder={t('devices.device_form.code', 'Chiave')}
                  value={code}
                  onChange={this.handleChange}
                  isInvalid={validated && 'code' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.code}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={!this.isFormValid() || this.initialHash === this.state.device_hash}
            onClick={this.handleConfirm}>
            <Trans i18nKey="common.button_confirm">Salva</Trans>
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            <Trans i18nKey="common.button_cancel">Annulla</Trans>
          </Button>
        </div>
      </>
    )
  }
}

export default DeviceForm
