import React from 'react'
import { Trans } from 'react-i18next'
import { Button } from 'react-bootstrap'

import Scarico from './tasks/scarico'
import Chiarifica from './tasks/chiarifica'
import Analisi from './tasks/analisi'
import Controllo from './tasks/controllo'
import AggiunteEnologiche from './tasks/aggiunte_enologiche'
import Travaso from './tasks/travaso'
import Pressatura from './tasks/pressatura'
import Lavaggio from './tasks/lavaggio'
import Massa from './tasks/massa'
import OperazioniSpeciali from './tasks/operazioni_speciali'
import Rimontaggio from './tasks/rimontaggio'
import AvvioFermentazione from './tasks/avvio_fermentazione'
import Ossigenazione from './tasks/ossigenazione'
import Filtrazione from './tasks/filtrazione'
import Sfecciatura from './tasks/sfecciatura'
import Salasso from './tasks/salasso'
import Destinazione from './tasks/destinazione'

class HistoryForm extends React.Component {
  static defaultProps = {
    closeForm: () => {},
    errors: {},
    operation: {},
  }

  renderTask = c => {
    let { operators } = this.props

    switch (c.task) {
      case 'scarico_uva':
      case 'scarico_vino':
        return <Scarico key={c.id} c={c} operators={operators} />
      case 'aggiunte_enologiche':
        return <AggiunteEnologiche key={c.id} c={c} operators={operators} />
      case 'operazioni_speciali':
        return <OperazioniSpeciali key={c.id} c={c} operators={operators} />
      case 'chiarifica':
        return <Chiarifica key={c.id} c={c} operators={operators} />
      case 'rimontaggio':
        return <Rimontaggio key={c.id} c={c} operators={operators} />
      case 'avvio_fermentazione':
        return <AvvioFermentazione key={c.id} c={c} operators={operators} />
      case 'travaso':
        return <Travaso key={c.id} c={c} operators={operators} />
      case 'ossigenazione':
        return <Ossigenazione key={c.id} c={c} operators={operators} />
      case 'pressatura':
        return <Pressatura key={c.id} c={c} operators={operators} />
      case 'filtrazione':
        return <Filtrazione key={c.id} c={c} operators={operators} />
      case 'controllo':
        return <Controllo key={c.id} c={c} operators={operators} />
      case 'analisi':
        return <Analisi key={c.id} c={c} operators={operators} />
      case 'sfecciatura':
        return <Sfecciatura key={c.id} c={c} operators={operators} />
      case 'lavaggio':
        return <Lavaggio key={c.id} c={c} operators={operators} />
      case 'destinazione':
        return <Destinazione key={c.id} c={c} operators={operators} />
      case 'salasso':
        return <Salasso key={c.id} c={c} operators={operators} />
      case 'massa':
        return <Massa key={c.id} c={c} operators={operators} />
      default:
        return null
    }
  }

  render() {
    let { operation } = this.props
    if (Object.keys(operation).length === 0) return null
    let { task, data } = operation
    let jsonData = JSON.parse(data ?? '{}')

    return (
      <>
        <h4 className="border-bottom text-primary p-1">{task.replace('_', ' ').toUpperCase()}</h4>
        <div className="flex-fill position-relative container">{this.renderTask(jsonData)}</div>
        <div className="border-top pt-2">
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            <Trans i18nKey="common.button_close">Chiudi</Trans>
          </Button>
        </div>
      </>
    )
  }
}

export default HistoryForm
