import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../axios'
import config from '../config'
import { addSuccessNotification, addErrorNotification } from './notifications'

export const branchOptions = [
  { id: 'Vino', name: 'Vino' },
  { id: 'Mosto', name: 'Mosto' },
]

const initialState = {
  origins: {},
  total: 0,
  loading: false,
  show_disabled: false,
  show_form: false,
  filtered_branch: [],
  current_origin: {},
  errors: {},
}

export const getOrigins = createAsyncThunk('origins/get_origins', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let current_page = parseInt(offset + 1 / limit) + 1 || 1
    let state = thunkApi.getState().origins
    let url = `${config.SERVER_API_URL}/origins?`
    if (filter) url = url + `&q=${encodeURIComponent(filter)}`
    if (state.show_disabled) url = url + `&disabled=true`
    if (limit) url = url + `&_end=${encodeURIComponent(limit)}`
    if (offset) url = url + `&_start=${encodeURIComponent(offset)}`
    if (sort_direction) url = url + `&_order=${encodeURIComponent(sort_direction)}`
    if (sort_column) url = url + `&_sort=${encodeURIComponent(sort_column)}`
    if (state.filtered_branch.length > 0) url = url + `&branch=${encodeURIComponent(state.filtered_branch.join(','))}`

    let response = await axios({
      url,
      method: 'GET',
    })
    let total = parseInt(response.headers['x-total-count'])
    return { origins: response.data, total, current_page }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})
export const createOrigin = createAsyncThunk('origins/create_origin', async (origin, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/origins`,
      method: 'POST',
      data: origin,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { origin: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification('Impossibile salvare la provenienza!'))
    return thunkApi.rejectWithValue({})
  }
})

export const updateOrigin = createAsyncThunk('origins/update_origin', async (origin, thunkApi) => {
  try {
    if (!origin.id) throw Error('Impossibile salvare!')
    let response = await axios({
      url: `${config.SERVER_API_URL}/origins/${origin.id}`,
      method: 'PUT',
      data: origin,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { origin: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification('Impossibile salvare la provenienza!'))
    return thunkApi.rejectWithValue({})
  }
})

export const resetPasswordOrigin = createAsyncThunk('origins/reset_password', async (origin, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/origins/${origin.id}/reset_password`,
      method: 'POST',
    })
    thunkApi.dispatch(addSuccessNotification('Reset password inviata per email'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile inviare l'email di reset password"))
    return thunkApi.rejectWithValue()
  }
})

export const toggleOrigin = createAsyncThunk('origins/toggle_origin', async (origin_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/origins/${origin_id}/toggle`,
      method: 'GET',
    })
    thunkApi.dispatch(addSuccessNotification('Utente modificato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification('Impossibile modificare la provenienza'))
    return thunkApi.rejectWithValue()
  }
})
export const deleteOrigin = createAsyncThunk('origins/delete_origin', async (origin_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/origins/${origin_id}`,
      method: 'DELETE',
    })
    thunkApi.dispatch(addSuccessNotification('Utente eliminato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification('Impossibile eliminare la provenienza'))
    return thunkApi.rejectWithValue()
  }
})

export const origininfoSlice = createSlice({
  name: 'origins',
  initialState,

  reducers: {
    setLoading: state => {
      state.loading = true
    },
    toggleDisabledFilter: state => {
      state.show_disabled = !state.show_disabled
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentOrigin: state => {
      state.current_origin = {}
    },
    updateErrors: (state, action) => {
      state.errors = action.payload.errors
    },
    setCurrentOrigin: (state, action) => {
      state.current_origin = action.payload
    },
    filterBranch: (state, action) => {
      state.filtered_branch = action.payload
      state.origins = []
      state.current_page = 1
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getOrigins.fulfilled, (state, action) => {
        let { origins, total, current_page } = action.payload
        state.origins = current_page === 1 ? origins : [...state.origins, ...origins]
        state.total = total
        state.current_page = current_page
        state.loading = false
      })
      .addCase(getOrigins.rejected, state => {
        state.origins = []
        state.total = 0
        state.current_page = 1
        state.loading = false
      })
      .addCase(updateOrigin.rejected, () => {
        throw new Error()
      })
      .addCase(createOrigin.rejected, () => {
        throw new Error()
      })
  },
})

export const {
  setLoading,
  toggleDisabledFilter,
  resetCurrentOrigin,
  toggleShowForm,
  updateErrors,
  setCurrentOrigin,
  filterBranch,
} = origininfoSlice.actions
export default origininfoSlice.reducer
