import React from 'react'
import { Button, Form, Alert } from 'react-bootstrap'
import i18next from 'i18next'
import hash from 'object-hash'
import { Trans } from 'react-i18next'

import validateFields from 'lib/validators/container'
import { updateErrors } from 'store/containers'

const { t } = i18next

const initialState = {
  id: null,
  name: '',
  tipologies: [],
  capacity: 0,
  quantity: 0,
  washing_status: '',
  enabled: true,
}

class ContainerForm extends React.Component {
  static defaultProps = {
    closeForm: () => {},
    onSave: () => {},
    errors: {},
    container: initialState,
    tipologiesOptions: [],
    washingOptions: [],
  }
  state = {
    container: initialState,
    container_hash: '',
  }

  setContainer = () => {
    let container = {
      ...this.state.container,
      ...JSON.parse(JSON.stringify(this.props.container)),
    }
    let container_hash = hash(container)
    this.setState({ container, container_hash })
    this.initialHash = hash(container)
  }
  componentDidMount() {
    this.setContainer()
  }

  componentDidUpdate = prevProps => {
    if (prevProps.container.id !== this.props.container.id) {
      this.setContainer()
    }
  }

  updateHash = () => {
    let { container } = this.state
    this.setState({ container_hash: hash(container) })
  }

  handleChange = ({ target: { name, value } }) => {
    let container = { ...this.state.container }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    container[name] = value
    this.setState({ container }, () => this.updateHash())
  }
  handleSelectChange = e => {
    let errors = { ...this.props.errors }
    delete errors['branch']
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ container: { ...this.state.container, branch: e.target.value } }, () => this.updateHash())
  }
  handleChangeWashing = ({ target: { name } }) => {
    let errors = { ...this.props.errors }
    delete errors['washing_status']
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ container: { ...this.state.container, washing_status: name } }, () => this.updateHash())
  }
  handleAddTipology = e => {
    let { tipologies } = this.state.container
    if (e.target.checked === true) {
      tipologies.push(e.target.name)
    } else {
      tipologies = tipologies.filter(s => s !== e.target.name)
    }
    this.setState({ container: { ...this.state.container, tipologies } }, () => this.updateHash())
  }
  handleIsEnabled = e => {
    let container = { ...this.state.container }
    container['enabled'] = e.target.checked
    this.setState({ container }, () => this.updateHash())
  }

  isFormValid = () => {
    let { name, branch } = this.state.container
    return name !== '' || branch !== ''
  }

  handleConfirm = async () => {
    let errors = validateFields({ ...this.state.container })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let saved = await this.props.onSave(this.state.container)
      if (saved) this.props.closeForm()
    }
    this.setState({ validated: true })
  }

  render() {
    let { validated, container } = this.state
    let { id, name, capacity, tipologies, washing_status, enabled } = container
    let { errors, tipologiesOptions, washingOptions } = this.props
    let title = id
      ? t('containers.container_form.edit_title', 'Modifica serbatoio')
      : t('containers.container_form.create_title', 'Crea serbatoio')

    return (
      <>
        <h4 className="border-bottom text-primary p-1">{title}</h4>
        <div className="flex-fill position-relative overflow-auto container">
          {errors?.from_server?.length > 0 && (
            <Alert variant="danger">
              <Alert.Heading>Error:</Alert.Heading>
              <ul>
                {errors.from_server.map((err, i) => (
                  <li key={`err-${i}`}>{err.detail}</li>
                ))}
              </ul>
            </Alert>
          )}
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="containers.container_form.name">Nome</Trans>
                </Form.Label>
                <span> *</span>
                <Form.Control
                  name="name"
                  placeholder={t('containers.container_form.name', 'Nome')}
                  value={name}
                  onChange={this.handleChange}
                  isInvalid={validated && 'name' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Check
                className="mt-5"
                type="switch"
                id="enabled"
                name="enabled"
                label="Abilitato"
                checked={enabled}
                onChange={this.handleIsEnabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="containers.container_form.capacity">Capacità</Trans>
                </Form.Label>
                <span> *</span>
                <div className="d-flex align-items-center" style={{ maxWidth: 150 }}>
                  <Form.Control
                    name="capacity"
                    placeholder={t('containers.container_form.capacity', 'Capacità')}
                    value={capacity}
                    onChange={this.handleChange}
                    isInvalid={validated && 'name' in errors}
                  />
                  <span style={{ paddingLeft: 10 }}>hl</span>
                </div>
                <Form.Control.Feedback type="invalid">{errors.capacity}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="containers.container_form.tipology">Tipologie</Trans>
                </Form.Label>
                <span> *</span>
                {tipologiesOptions.map(t => (
                  <Form.Check
                    name={t.name}
                    label={t.name}
                    onChange={this.handleAddTipology}
                    checked={tipologies.includes(t.name)}
                  />
                ))}
                <Form.Control.Feedback type="invalid">{errors.tipologies}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="containers.container_form.washing_status">Stato di lavaggio</Trans>
                </Form.Label>
                <span> *</span>
                {washingOptions.map(ws => (
                  <div className="d-flex align-items-center">
                    <Form.Check
                      name={ws.name}
                      onChange={this.handleChangeWashing}
                      checked={washing_status === ws.name}
                    />
                    <i className={`fas fa-square ps-2 pe-2`} style={{ color: ws.color }} />
                    <span>{ws.name}</span>
                  </div>
                ))}
                <Form.Control.Feedback type="invalid">{errors.washing_status}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={!this.isFormValid() || this.initialHash === this.state.container_hash}
            onClick={this.handleConfirm}>
            <Trans i18nKey="common.button_confirm">Salva</Trans>
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            <Trans i18nKey="common.button_cancel">Annulla</Trans>
          </Button>
        </div>
      </>
    )
  }
}

export default ContainerForm
