import { configureStore } from '@reduxjs/toolkit'

import userinfoReducer from './userinfo'
import usersReducer from './users'
import originsReducer from './origins'
import kindsReducer from './kinds'
import typologiesReducer from './typologies'
import additionsReducer from './additions'
import analysesReducer from './analyses'
import containersReducer from './containers'
import devicesReducer from './devices'
import historiesReducer from './histories'
import notificationsReducer from './notifications'

const addGlobalResetEnhancer = createStore => (reducer, initialState, enhancer) => {
  const resettableReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
      return reducer(undefined, action)
    } else {
      return reducer(state, action)
    }
  }
  return createStore(resettableReducer, initialState, enhancer)
}

export function makeStore() {
  return configureStore({
    reducer: {
      userinfo: userinfoReducer,
      users: usersReducer,
      origins: originsReducer,
      kinds: kindsReducer,
      typologies: typologiesReducer,
      additions: additionsReducer,
      analyses: analysesReducer,
      containers: containersReducer,
      devices: devicesReducer,
      histories: historiesReducer,
      notifications: notificationsReducer,
    },
    enhancers: [addGlobalResetEnhancer],
  })
}

const store = makeStore()
export default store

export const resetStore = () => store.dispatch({ type: 'RESET_STORE' })
