import React from 'react'
import cookies from 'browser-cookies'
import { Route, Routes } from 'react-router-dom'
import { Container, Col, Row, Card } from 'react-bootstrap'
import withRouter from 'utils/withRouter'
import TopMenu from '../common/top_menu'
import Sidebar from '../common/sidebar'
import Dashboard from '../dashboard'
import Users from '../users'
import Origins from '../origins'
import Additions from '../additions'
import Analyses from '../analyses'
import Kinds from '../kinds'
import Typologies from '../typologies'
import Containers from '../containers'
import Devices from '../devices'
import Histories from '../histories'

import pages from './pages'

class AdminLayout extends React.Component {
  state = { ready: false }

  checkInfoCookie = () => {
    try {
      const infoAdminCookie = cookies.get('palama-admin')
      //JSON.parse(atob(decodeURIComponent(infoAdminCookie)))
      if (!infoAdminCookie) throw new Error('Errore nel recupero del cookiee')
      this.setState({ ready: true })
    } catch (err) {
      cookies.erase('palama-admin')
      window.location = '/login'
    }
  }

  componentDidMount() {
    this.checkInfoCookie()
  }

  render() {
    if (!this.state.ready) return null
    let { pathname } = this.props.router.location
    let page = pages.find(p => p.pathname === pathname)
    return (
      <>
        <TopMenu />
        <Container fluid style={{ padding: 0 }}>
          <Row className="w-100">
            <Col lg="2">
              <Sidebar pages={pages} />
            </Col>
            <Col lg="10" style={{ height: 'calc(100vh - 4rem)', top: '4rem', position: 'relative', padding: 0 }}>
              <Card className="w-100 h-100">
                <Card.Header>
                  <Card.Title className="text-bold pt-1 pb-1">
                    <i className={`fas ${page.icon} pe-3`} />
                    {page?.name}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Routes>
                    <Route exact path="/" element={<Dashboard pages={pages} />} />
                    <Route exact path="/containers" element={<Containers />} />
                    <Route exact path="/users" element={<Users />} />
                    <Route exact path="/origins" element={<Origins />} />
                    <Route exact path="/additions" element={<Additions />} />
                    <Route exact path="/analyses" element={<Analyses />} />
                    <Route exact path="/kinds" element={<Kinds />} />
                    <Route exact path="/typologies" element={<Typologies />} />
                    <Route exact path="/devices" element={<Devices />} />
                    <Route exact path="/histories" element={<Histories />} />
                  </Routes>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default withRouter(AdminLayout)
