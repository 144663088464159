import React from 'react'

class Scarico extends React.Component {
  render() {
    let { c, operators } = this.props
    let user = operators.find(u => u.id === c.user_id)
    return (
      <div className="p-3 overflow-y" style={{ maxHeight: 580 }}>
        <div className="row">
          <div className="col-5">
            <div className="row text-muted"> Effettuato in data: </div>
            <div className="row fw-bold">{c.created_at}</div>
          </div>
          <div className="col-5">
            <div className="row text-muted">Effettuato dall'operatore:</div>
            <div className="row fw-bold">{user.fullname}</div>
          </div>
        </div>
        <div className="row pt-3">Serbatoi interessati:</div>
        <ul className="pt-2">
          {c.containers.map(s => (
            <li>
              <b className="pe-2">
                {s.name} == {s.selQty} hl{' '}
              </b>
            </li>
          ))}
        </ul>
        <div className="row">
          <div className="col-5">
            <div className="row pt-3">
              Nome: <b>{c.name}</b>
            </div>
            <div className="row pt-3">
              Colore: <b>{c.color}</b>
            </div>
            <div className="row pt-3">
              Vitigno: <b>{c.kind.join(', ')}</b>
            </div>
            <div className="row pt-3">
              Origine: <b>{c.origin}</b>
            </div>
          </div>
          <div className="col-5">
            <div className="row pt-3">
              QLI: <b>{c.qli}</b>
            </div>
            <div className="row pt-3">
              Zuccheri (g): <b>{c.sugar}</b>
            </div>
          </div>
        </div>
        <div className="row pt-3">Aggiunte allo trasporto:</div>
        {(c.transport ?? []).map(t => (
          <li key={t.id}>
            <b>
              {t.name} {t.name.includes('Altro') ? ': ' + t.selName : ''} - {t.selQty} {t.unit}
            </b>
          </li>
        ))}
        {(c.transport ?? []).length === 0 && <div>Nessuna aggiunta</div>}
        <div className="row pt-3">Aggiunte allo scarico:</div>
        {(c.unload ?? []).map(t => (
          <li key={t.id}>
            <b>
              {t.name} {t.name.includes('Altro') ? ': ' + t.selName : ''} - {t.selQty} {t.unit}
            </b>
          </li>
        ))}
        {(c.unload ?? []).length === 0 && <div>Nessuna aggiunta</div>}
        <div className="row text-muted pt-4">NOTE:</div>
        <div className="row">{c.note}</div>
      </div>
    )
  }
}

export default Scarico
