import React from 'react'
import { Button } from 'react-bootstrap'
import QRCode from 'react-qr-code'
import ReactToPrint from 'react-to-print'

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: 'white' }}
        ref={el => this.props.setRef(el)}>
        {this.props.containers.map(c => (
          <div style={{ flex: '0 0 28%', margin: 10 }}>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={`${c.name}|${c.id}`}
              viewBox={`0 0 256 256`}
            />
            <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 40 }}>{c.name}</div>
          </div>
        ))}
      </div>
    )
  }
}

class QrCodePrinter extends React.Component {
  componentRef = null

  state = { showModal: false }

  setRef = ref => {
    this.componentRef = ref
  }
  render() {
    return (
      <div>
        <ReactToPrint
          onBeforeGetContent={() => this.setState({ showModal: true })}
          onBeforePrint={() => this.setState({ showModal: true })}
          trigger={() => (
            <Button>
              <i className="fas fa-print fa-fw text-start" /> Genera QRCode
            </Button>
          )}
          content={() => this.componentRef}
        />
        <div style={{ display: 'none' }}>
          <ComponentToPrint
            show={this.state.showModal}
            printRef={this.componentRef}
            setRef={this.setRef}
            containers={this.props.containers}
          />
        </div>
      </div>
    )
  }
}

export default QrCodePrinter
