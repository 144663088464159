import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import withRouter from 'utils/withRouter'

class Sidebar extends React.Component {
  buttonRef = React.createRef()

  render() {
    let { pathname } = this.props.router.location

    return (
      <Dropdown.Menu show style={{ height: 'calc(100vh - 4rem)', top: '4rem', position: 'relative' }}>
        {this.props.pages.map(p => {
          return (
            <>
              <Dropdown.Item
                eventKey={p.name}
                as={Link}
                to={p.pathname}
                active={pathname === p.pathname}
                style={{ whiteSpace: 'unset' }}>
                <i className={`fas ${p.icon} pe-3`} style={{ width: '2rem', alignSelf: 'center' }} />
                {p.name}
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )
        })}
      </Dropdown.Menu>
    )
  }
}

function mapStateToProps(state, props) {
  return {}
}

export default connect(mapStateToProps)(withRouter(Sidebar))
