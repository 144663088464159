import React from 'react'
import { connect } from 'react-redux'
import { Button, Card, Form, Alert } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'
import { login } from '../../store/userinfo'

import logo from '../../assets/images/logo-banner.png'

const mstp = state => {
  return {
    access_token: state.userinfo.access_token,
    user: state.userinfo.user,
    loading: state.userinfo.loading,
    errors: state.userinfo.errors,
  }
}
class LoginLayout extends React.Component {
  state = {
    username: '',
    password: '',
    hide_password: true,
  }

  onLogin = async () => {
    let { username, password } = this.state
    await this.props.dispatch(login({ username, password }))
  }

  handleChange = ({ target }) => {
    let { name, value } = target
    this.setState({ [name]: value })
  }

  hidePassword = () => this.setState({ hide_password: !this.state.hide_password })

  render() {
    let { errors, access_token, loading } = this.props
    let { username, password } = this.state
    if (access_token) return <Navigate to="/" />
    return (
      <div className="d-flex justify-content-center align-items-center mt-5 flex-column">
        <Card style={{ width: '28rem', backgroundColor: '#595959' }} className="p-5 pb-1 mt-auto text-center">
          <Card.Img variant="top" src={logo} className="align-self-center" style={{ height: 200, width: 250 }} />
          <Card.Body className="mt-5">
            <Alert
              variant={'errors'}
              show={errors !== ''}
              transition={false}
              style={{ color: 'white', borderColor: 'white', borderWidth: 1 }}>
              {errors}
            </Alert>
            <Form onKeyDown={this.handleKeyDown}>
              <Form.Group className="mb-3" controlId="formBasicUsername">
                <Form.Control
                  name="username"
                  type="text"
                  placeholder="Nome utente"
                  onChange={this.handleChange}
                  value={username}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={this.handleChange}
                  value={password}
                />
              </Form.Group>
              <Button
                variant="warning"
                className="w-100 rounded-3 mt-2 mb-2"
                disabled={username === '' || password === '' || loading === true}
                onClick={this.onLogin}>
                {loading === true ? <span className="spinner-border spinner-border-sm"></span> : 'ACCEDI'}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    )
  }
}

export default connect(mstp)(LoginLayout)
