import React from 'react'
import cookies from 'browser-cookies'
import axios from '../../axios'
import config from '../../config'
import { getToken } from '../../utils'

export default class Logout extends React.Component {
  async componentDidMount() {
    // let token = cookies.get('palama-admin')
    // let headers = { Authorization: `base_laravel-token ${getToken}` }
    await axios({ url: `${config.SERVER_API_URL}/users`, method: 'GET' })
    cookies.erase('palama-admin')
    window.location = '/login'
  }
  render() {
    return null
  }
}
