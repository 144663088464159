import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form } from 'react-bootstrap'

import {
  toggleShowForm,
  toggleDisabledFilter,
  resetCurrentDevice,
  setCurrentDevice,
  getDevices,
  updateDevice,
  createDevice,
  deleteDevice,
  toggleDevice,
  cleanDevices,
} from '../../store/devices'
import ResponsiveTable from '../system_wide/responsive_table'
import ActionsDropdown from '../system_wide/actions_dropdown'
import SearchBox from '../system_wide/search_box'
import DeviceForm from './form'

const mstp = state => {
  let { devices, total, show_disabled, show_form, current_device, errors } = state.devices
  return {
    devices,
    total,
    show_disabled,
    show_form,
    current_device,
    errors,
  }
}

class DevicesList extends React.Component {
  static defaultProps = {
    devices: [],
    getData: () => {},
    columns: [],
    filter: null,
    show_disabled: true,
  }

  state = {
    show_modal_toggle: false,
    show_modal_delete: false,
    show_clean: false,
    device_to_toggle: { id: '', name: '', code: '', enabled: null },
    device_to_delete: { id: '', name: '', code: '' },
    filter: '',
  }

  tableRef = null

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      onClick: rowData => {
        this.props.dispatch(setCurrentDevice(rowData))
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-ban',
      text: 'Disattiva',
      visible: rowData => rowData.enabled === 1,
      onClick: ({ id, name, enabled }) => {
        this.setState({
          show_modal_toggle: true,
          device_to_toggle: { id, name, enabled },
        })
      },
    },
    {
      icon: 'fa-check',
      text: 'Attiva',
      visible: rowData => rowData.enabled === 0,
      onClick: ({ id, name, enabled }) => {
        this.setState({
          show_modal_toggle: true,
          device_to_toggle: { id, name, enabled },
        })
      },
    },
    {
      icon: 'fa-trash',
      text: 'Elimina',
      onClick: ({ id, name }) => {
        this.setState({
          show_modal_delete: true,
          device_to_delete: { id, name },
        })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '4rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Nome',
      className: 'd-lg-block flex-fill',
      style: { width: '10%', minWidth: '8rem' },
      sortable: true,
      data: 'name',
    },
    {
      title: 'Chiave',
      className: 'd-lg-block flex-fill',
      style: { width: '10%', minWidth: '8rem' },
      sortable: true,
      data: 'code',
    },
  ]

  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(getDevices({ offset: index - 1, limit: chunkSize, sortData, filter }))
  }

  onToggleForm = async () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentDevice())
    }
    this.props.dispatch(toggleShowForm())
  }
  onToggleClean = () => this.setState({ show_clean: true })

  onCancelToggle = () => {
    this.setState({ show_modal_toggle: false, device_to_toggle: { id: '', name: '', enabled: null } })
  }
  onCancelDelete = () => {
    this.setState({ show_modal_delete: false, device_to_delete: { id: '', name: '' } })
  }
  onCloseModal = () => this.setState({ show_clean: false })

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  onFilterChange = filter => this.setState({ filter })
  onChangeFilterDisabled = () => {
    this.props.dispatch(toggleDisabledFilter())
    this.tableRef.current.refreshData()
  }

  saveDevice = async device => {
    try {
      if (device.id !== null) {
        await this.props.dispatch(updateDevice(device))
        this.tableRef.current.refreshData()
      } else {
        await this.props.dispatch(createDevice(device))
        this.tableRef.current.refreshData()
      }
      return true
    } catch (err) {
      return false
    }
  }
  onConfirmToggle = async () => {
    let device = this.state.device_to_toggle
    await this.props.dispatch(toggleDevice(device.id))
    this.setState({ show_modal_toggle: false, device_to_toggle: { id: '', name: '', enabled: null } })
    this.tableRef.current.refreshData()
  }
  onConfirmDelete = async () => {
    let device = this.state.device_to_delete
    await this.props.dispatch(deleteDevice(device.id))
    this.setState({ show_modal_delete: false, device_to_delete: { id: '', name: '' } })
    this.tableRef.current.refreshData()
  }
  onConfirmClean = async () => {
    await this.props.dispatch(cleanDevices())
    this.setState({ show_clean: false })
    this.tableRef.current.refreshData()
  }

  rowClasses = ({ enabled }) => (enabled === 0 ? 'bg-warning bg-gradient bg-opacity-50' : '')

  render() {
    let { devices, total, current_device, show_form, show_disabled, errors } = this.props
    if (!devices) return null

    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-1 pe-lg-1 pt-2 zi-1 h-100 w-100">
        <div className="d-flex flex-column flex-fill overflow-auto">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <Button onClick={this.onToggleForm} disabled={this.props.show_form}>
                  <i className="fas fa-plus fa-fw text-start" />
                  Aggiungi device
                </Button>
              </div>
              <div className="flex-fill">
                <Button className="float-md-end ms-2" onClick={this.onToggleClean} disabled={this.props.show_clean}>
                  <i className="fas fa-trash fa-fw" />
                </Button>
                <SearchBox
                  value={this.state.filter}
                  onChange={this.onFilterChange}
                  disabled={this.props.show_form}
                  className="float-md-end ms-3"
                />
                <Form.Check
                  className="float-md-end mt-2"
                  type="switch"
                  id="deactivated-switch"
                  label="Nascondi disabilitati"
                  value={show_disabled === true}
                  onChange={this.onChangeFilterDisabled}
                />
              </div>
            </div>
          </div>
          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill border"
            rowClasses={this.rowClasses}
            menuWidth="45rem"
            data={devices}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            showMenu={show_form}
            menuContent={DeviceForm}
            menuContentProps={{
              device: current_device,
              closeForm: this.onToggleForm,
              onSave: this.saveDevice,
              errors,
              dispatch: this.props.dispatch,
            }}
            filter={this.state.filter}
          />
        </div>
        <Modal backdrop="static" centered show={this.state.show_modal_toggle} onHide={this.onCancelToggle}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.device_to_toggle.enabled === 1 ? 'Disattiva' : 'Attiva'} device</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere sul <b>{this.state.device_to_toggle.name}</b> click su Prosegui
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancelToggle} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmToggle} variant="warning">
              Prosegui
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal backdrop="static" centered show={this.state.show_modal_delete} onHide={this.onCancelDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Elimina device</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere ad eliminiare la device <b>{this.state.device_to_delete.name}</b> click su Elimina
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancelDelete} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal backdrop="static" centered show={this.state.show_clean} onHide={this.onCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Pulizia device</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Per procedere ad eliminiare i device non associati click su Pulisci</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCloseModal} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmClean} variant="danger">
              Pulisci
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default connect(mstp)(DevicesList)
