import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../axios'
import config from '../config'
import { addSuccessNotification, addErrorNotification } from './notifications'

export const tipologiesOptions = [
  { id: 'Fisso', name: 'Fisso' },
  { id: 'Mobile', name: 'Mobile' },
  { id: 'Acciaio', name: 'Acciaio' },
  { id: 'Vetroresina', name: 'Vetroresina' },
  { id: 'Semprepieni', name: 'Semprepieni' },
  { id: 'Vasca cemento', name: 'Vasca cemento' },
  { id: 'Cisterna interrata', name: 'Cisterna interrata' },
  { id: 'Barrique', name: 'Barrique' },
]

export const washingOptions = [
  { id: "Sanitificato pronto all'uso", name: "Sanitificato pronto all'uso", color: '#018f31' },
  { id: "Sciacquato pronto all'uso", name: "Sciacquato pronto all'uso", color: '#0231bd' },
  { id: 'Sciacquato da rivedere', name: 'Sciacquato da rivedere', color: '#787878' },
  { id: 'Sciacquato da sanificare', name: 'Sciacquato da sanificare', color: 'black' },
  { id: 'Non lavato - Non sanificato', name: 'Non lavato - Non sanificato', color: 'orange' },
]
const initialState = {
  containers: [],
  total: 0,
  current_page: 1,
  loading: false,
  show_disabled: false,
  show_form: false,
  current_container: {},
  filtered_tipologies: [],
  errors: {},
}

export const getContainers = createAsyncThunk('containers/get_containers', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData ?? { sort_column: '', sort_direction: '' }
    let current_page = parseInt(offset + 1 / limit) + 1 || 1
    let state = thunkApi.getState().containers
    let url = `${config.SERVER_API_URL}/containers?`
    if (filter) url = url + `&q=${encodeURIComponent(filter)}`
    if (state.show_disabled) url = url + `&disabled=true`
    if (limit) url = url + `&_end=${encodeURIComponent(limit)}`
    if (offset) url = url + `&_start=${encodeURIComponent(offset)}`
    if (sort_direction) url = url + `&_order=${encodeURIComponent(sort_direction)}`
    if (sort_column) url = url + `&_sort=${encodeURIComponent(sort_column)}`
    if (state.filtered_tipologies.length > 0)
      url = url + `&tipologies=${encodeURIComponent(state.filtered_tipologies.join(','))}`

    let response = await axios({
      url,
      method: 'GET',
    })
    let total = parseInt(response.headers['x-total-count'])
    return { containers: response.data, total, current_page }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})
export const createContainer = createAsyncThunk('containers/create_container', async (container, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/containers`,
      method: 'POST',
      data: container,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { container: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification('Impossibile salvare il serbatoio!'))
    return thunkApi.rejectWithValue({})
  }
})

export const updateContainer = createAsyncThunk('containers/update_container', async (container, thunkApi) => {
  try {
    if (!container.id) throw Error('Impossibile salvare!')
    let response = await axios({
      url: `${config.SERVER_API_URL}/containers/${container.id}`,
      method: 'PUT',
      data: container,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { container: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification('Impossibile salvare il serbatoio!'))
    return thunkApi.rejectWithValue({})
  }
})

export const toggleContainer = createAsyncThunk('containers/toggle_container', async (container_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/containers/${container_id}/toggle`,
      method: 'GET',
    })
    thunkApi.dispatch(addSuccessNotification('Serbatoio modificato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification('Impossibile modificare il serbatoio'))
    return thunkApi.rejectWithValue()
  }
})
export const deleteContainer = createAsyncThunk('containers/delete_container', async (container_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/containers/${container_id}`,
      method: 'DELETE',
    })
    thunkApi.dispatch(addSuccessNotification('Serbatoio eliminato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification('Impossibile eliminare il serbatoio'))
    return thunkApi.rejectWithValue()
  }
})

export const containerSlice = createSlice({
  name: 'containers',
  initialState,

  reducers: {
    setLoading: state => {
      state.loading = true
    },
    filterTipologies: (state, action) => {
      state.filtered_tipologies = action.payload
      state.containers = []
      state.current_page = 1
    },
    toggleDisabledFilter: state => {
      state.show_disabled = !state.show_disabled
      state.containers = []
      state.current_page = 1
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentContainer: state => {
      state.current_container = {}
    },
    updateErrors: (state, action) => {
      state.errors = action.payload.errors
    },
    setCurrentContainer: (state, action) => {
      state.current_container = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getContainers.fulfilled, (state, action) => {
        let { containers, total, current_page } = action.payload
        state.containers = current_page === 1 ? containers : [...state.containers, ...containers]
        state.total = total
        state.current_page = current_page
        state.loading = false
      })
      .addCase(getContainers.rejected, (state, action) => {
        state.containers = []
        state.total = 0
        state.current_page = 1
        state.loading = false
      })
      .addCase(updateContainer.rejected, () => {
        throw new Error()
      })
      .addCase(createContainer.rejected, () => {
        throw new Error()
      })
  },
})

export const {
  setLoading,
  toggleDisabledFilter,
  resetCurrentContainer,
  toggleShowForm,
  updateErrors,
  setCurrentContainer,
  filterTipologies,
} = containerSlice.actions
export default containerSlice.reducer
