import React from 'react'
import { connect } from 'react-redux'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import logo from '../../assets/images/logo.png'
import { getUserInfo } from 'store/userinfo'

const mstp = state => {
  return { user: state.userinfo.user }
}

class TopMenu extends React.Component {
  componentDidMount() {
    this.props.dispatch(getUserInfo())
  }
  render() {
    let { user } = this.props
    return (
      <Navbar variant="dark" style={{ backgroundColor: 'darkslategrey' }} fixed="top">
        <Nav className="me-auto">
          <Navbar.Brand href="#home" className="d-flex ps-2">
            <img alt="logo palama" width="30" height="30" src={logo} className="d-inline-block align-top" />
            <div style={{ fontSize: 20 }} className="ps-3">
              Vinicola Palamà
            </div>
          </Navbar.Brand>
        </Nav>
        <Nav className="me-2">
          <NavDropdown align="end" title={`${user?.first_name} ${user?.last_name}`} menuVariant="dark">
            <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar>
    )
  }
}

export default connect(mstp)(TopMenu)
