import React from 'react'
import { Trans } from 'react-i18next'
import { Dropdown, DropdownButton, Form } from 'react-bootstrap'

class CheckboxFilter extends React.Component {
  static defaultProps = {
    items: [],
    filteredItems: [],
    onChangeFilter: () => null,
    disabled: false,
    multi: true,
  }

  handleChange = id => e => {
    e.stopPropagation()
    let filtered_items = [...this.props.filteredItems]
    if (id === 0) {
      filtered_items = filtered_items.length !== this.props.items.length ? this.props.items.map(r => r.id) : []
    } else {
      if (filtered_items.includes(id)) {
        filtered_items = filtered_items.filter(_id => _id !== id)
      } else {
        filtered_items.push(id)
      }
    }
    if (id !== 0 && this.props.multi === false) {
      if (filtered_items.includes(id)) filtered_items = [id]
      else filtered_items = []
    }
    this.props.onChangeFilter(filtered_items)
  }

  render() {
    let { className, label, items, filteredItems, disabled, multi, style } = this.props
    return (
      <DropdownButton
        title={
          <>
            <i className="fas fa-filter fa-fw text-start" />
            {label}
          </>
        }
        disabled={disabled}
        className={className}>
        {items.map(item => {
          return (
            <Dropdown.Item
              key={`role-${item.id}`}
              className="d-flex gap-3 align-items-center"
              onClick={this.handleChange(item.id)}>
              <Form.Check
                id={item.id}
                value={item.id}
                name={item.name}
                checked={filteredItems.includes(item.id)}
                onChange={this.handleChange(item.id)}
              />
              <label htmlFor={item.id}>{item.name}</label>
            </Dropdown.Item>
          )
        })}
        {multi === true && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item key={`all`} className="d-flex gap-3 align-items-center" onClick={this.handleChange(0)}>
              <Form.Check
                id={0}
                value={0}
                name={'all'}
                checked={this.props.items.length === filteredItems.length}
                onChange={this.handleChange(0)}
              />
              <label htmlFor={'all'}>
                {this.props.items.length === filteredItems.length && (
                  <Trans i18nKey="system_wide.checkbox_filter.deselect_all">Deseleziona tutti</Trans>
                )}
                {this.props.items.length > filteredItems.length && (
                  <Trans i18nKey="system_wide.checkbox_filter.select_all">Seleziona tutti</Trans>
                )}
              </label>
            </Dropdown.Item>
          </>
        )}
      </DropdownButton>
    )
  }
}

export default CheckboxFilter
