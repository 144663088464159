import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../axios'
import config from '../config'
import { addSuccessNotification, addErrorNotification } from './notifications'

const initialState = {
  typologies: {},
  total: 0,
  loading: false,
  show_disabled: false,
  show_form: false,
  current_typology: {},
  errors: {},
}

export const getTypologies = createAsyncThunk('typologies/get_typologies', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let state = thunkApi.getState().typologies
    let url = `${config.SERVER_API_URL}/typologies?`
    if (filter) url = url + `&q=${encodeURIComponent(filter)}`
    if (state.show_disabled) url = url + `&disabled=true`
    if (limit) url = url + `&_end=${encodeURIComponent(limit)}`
    if (offset) url = url + `&_start=${encodeURIComponent(offset)}`
    if (sort_direction) url = url + `&_order=${encodeURIComponent(sort_direction)}`
    if (sort_column) url = url + `&_sort=${encodeURIComponent(sort_column)}`

    let response = await axios({
      url,
      method: 'GET',
    })
    let total = parseInt(response.headers['x-total-count'])
    return { typologies: response.data, total }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})
export const createTypology = createAsyncThunk('typologies/create_typology', async (typology, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/typologies`,
      method: 'POST',
      data: typology,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { typology: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification('Impossibile salvare la tipologia!'))
    return thunkApi.rejectWithValue({})
  }
})

export const updateTypology = createAsyncThunk('typologies/update_typology', async (typology, thunkApi) => {
  try {
    if (!typology.id) throw Error('Impossibile salvare!')
    let response = await axios({
      url: `${config.SERVER_API_URL}/typologies/${typology.id}`,
      method: 'PUT',
      data: typology,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { typology: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification('Impossibile salvare la tipologia!'))
    return thunkApi.rejectWithValue({})
  }
})

export const resetPasswordTypology = createAsyncThunk('typologies/reset_password', async (typology, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/typologies/${typology.id}/reset_password`,
      method: 'POST',
    })
    thunkApi.dispatch(addSuccessNotification('Reset password inviata per email'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile inviare l'email di reset password"))
    return thunkApi.rejectWithValue()
  }
})

export const toggleTypology = createAsyncThunk('typologies/toggle_typology', async (typology_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/typologies/${typology_id}/toggle`,
      method: 'GET',
    })
    thunkApi.dispatch(addSuccessNotification('Utente modificato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification('Impossibile modificare la tipologia'))
    return thunkApi.rejectWithValue()
  }
})
export const deleteTypology = createAsyncThunk('typologies/delete_typology', async (typology_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/typologies/${typology_id}`,
      method: 'DELETE',
    })
    thunkApi.dispatch(addSuccessNotification('Utente eliminato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification('Impossibile eliminare la tipologia'))
    return thunkApi.rejectWithValue()
  }
})

export const typologyinfoSlice = createSlice({
  name: 'typologies',
  initialState,

  reducers: {
    setLoading: state => {
      state.loading = true
    },
    toggleDisabledFilter: state => {
      state.show_disabled = !state.show_disabled
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentTypology: state => {
      state.current_typology = {}
    },
    updateErrors: (state, action) => {
      state.errors = action.payload.errors
    },
    setCurrentTypology: (state, action) => {
      state.current_typology = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTypologies.fulfilled, (state, action) => {
        let { typologies, total } = action.payload
        state.typologies = typologies
        state.total = total
        state.loading = false
      })
      .addCase(getTypologies.rejected, (state, action) => {
        state.typologies = []
        state.total = 0
        state.loading = false
      })
      .addCase(updateTypology.rejected, () => {
        throw new Error()
      })
      .addCase(createTypology.rejected, () => {
        throw new Error()
      })
  },
})

export const {
  setLoading,
  toggleDisabledFilter,
  resetCurrentTypology,
  toggleShowForm,
  updateErrors,
  setCurrentTypology,
} = typologyinfoSlice.actions
export default typologyinfoSlice.reducer
