import React from 'react'
import { Button, Form, Alert } from 'react-bootstrap'
import i18next from 'i18next'
import hash from 'object-hash'
import { Trans } from 'react-i18next'

import validateFields from 'lib/validators/origin'
import { updateErrors } from 'store/origins'

const { t } = i18next

const initialState = {
  id: null,
  name: '',
  branch: [],
  enabled: true,
}

class OriginForm extends React.Component {
  static defaultProps = {
    closeForm: () => {},
    onSave: () => {},
    errors: {},
    origin: initialState,
  }
  state = {
    origin: initialState,
    origin_hash: '',
  }

  setOrigin = () => {
    let origin = {
      ...this.state.origin,
      ...this.props.origin,
    }
    let origin_hash = hash(origin)
    this.setState({ origin, origin_hash })
    this.initialHash = hash(origin)
  }
  componentDidMount() {
    this.setOrigin()
  }

  componentDidUpdate = prevProps => {
    if (prevProps.origin.id !== this.props.origin.id) {
      this.setOrigin()
    }
  }

  updateHash = () => {
    let { origin } = this.state
    this.setState({ origin_hash: hash(origin) })
  }

  handleChange = ({ target: { name, value } }) => {
    let origin = { ...this.state.origin }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    origin[name] = value
    this.setState({ origin }, () => this.updateHash())
  }
  handleIsEnabled = e => {
    let origin = { ...this.state.origin }
    origin['enabled'] = e.target.checked
    this.setState({ origin }, () => this.updateHash())
  }
  handleAddBranch = e => {
    let branch = [...this.state.origin.branch]
    if (e.target.checked === true) {
      branch.push(e.target.name)
    } else {
      branch = branch.filter(s => s !== e.target.name)
    }
    this.setState({ origin: { ...this.state.origin, branch } }, () => this.updateHash())
  }

  isFormValid = () => {
    let { name } = this.state.origin
    return name !== ''
  }

  handleConfirm = async () => {
    let errors = validateFields({ ...this.state.origin })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let saved = await this.props.onSave(this.state.origin)
      if (saved) this.props.closeForm()
    }
    this.setState({ validated: true })
  }

  render() {
    let { validated, origin } = this.state
    let { id, name, branch, enabled } = origin
    let { errors } = this.props
    let title = id
      ? t('origins.origin_form.edit_title', 'Modifica provenienza/produttore')
      : t('origins.origin_form.create_title', 'Crea provenienza/produttore')

    return (
      <>
        <h4 className="border-bottom text-primary p-1">{title}</h4>
        <div className="flex-fill position-relative overflow-auto container">
          {errors?.from_server?.length > 0 && (
            <Alert variant="danger">
              <Alert.Heading>Error:</Alert.Heading>
              <ul>
                {errors.from_server.map((err, i) => (
                  <li key={`err-${i}`}>{err.detail}</li>
                ))}
              </ul>
            </Alert>
          )}
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="origins.origin_form.name">Nome</Trans>
                </Form.Label>
                <span> *</span>
                <Form.Control
                  name="name"
                  placeholder={t('origins.origin_form.name', 'Nome')}
                  value={name}
                  onChange={this.handleChange}
                  isInvalid={validated && 'name' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Check
                className="mt-5"
                type="switch"
                id="enabled"
                name="enabled"
                label="Abilitato"
                checked={enabled}
                onChange={this.handleIsEnabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="additions.addition_form.actions">Visualizza in</Trans>
                </Form.Label>
                <Form.Check
                  name={'Vino'}
                  label={'Vino'}
                  onChange={this.handleAddBranch}
                  checked={branch.includes('Vino')}
                />
                <Form.Check
                  name={'Mosto'}
                  label={'Mosto'}
                  onChange={this.handleAddBranch}
                  checked={branch.includes('Mosto')}
                />
                <Form.Control.Feedback type="invalid">{errors.branch}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={!this.isFormValid() || this.initialHash === this.state.origin_hash}
            onClick={this.handleConfirm}>
            <Trans i18nKey="common.button_confirm">Salva</Trans>
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            <Trans i18nKey="common.button_cancel">Annulla</Trans>
          </Button>
        </div>
      </>
    )
  }
}

export default OriginForm
